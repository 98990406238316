/* eslint-disable */
let JGAnalysisBaseUrl;
let app_id;
let show_log = true;
let session_id;

let network_type;

function toast(msg, params) {
	if (show_log) {
		console.warn('jganalytics', msg, params)
	}
}

// 注册
function init(params) {

	show_log = params.showLog

	if (!params) {
		toast('注册失败：缺少参数')
		return
	}
	let {
		appId,
		url,
		sessionId
	} = params

	if (!appId) {
		toast('注册失败：缺少必要参数appId')
		return
	}
	if (!url) {
		toast('注册失败：缺少必要参数url')
		return
	}

	JGAnalysisBaseUrl = url
	app_id = appId
	session_id = sessionId

	let registerData = {
		...getBaseParams()
	}

	var fileUrl = formatGetParams(JGAnalysisBaseUrl + '/fe/collectRegist.gif', registerData)
	uploadData(1, fileUrl, registerData)
}

function isRegister() {
	if (JGAnalysisBaseUrl && app_id) {
		return true
	}
	return false
}

// 埋点
function onEvent(params) {
	if (!isRegister()) {
		toast('请先注册')
		return
	}
	if (!params) {
		toast('埋点失败：缺少参数')
		return
	}
	let {
		eventId,
		eventType,
		pageId,
		userId,
		kvMap
	} = params
	if (!eventId) {
		toast('埋点失败：缺少必要参数eventId')
		return
	}
	if (!eventType) {
		toast('埋点失败：缺少必要参数eventType')
		return
	}

	// 获取基础参数
	let collectData = {
		...getBaseParams()
	}
	collectData.eventId = eventId
	collectData.eventType = eventType

	pageId && (collectData.pageId = pageId)
	userId && (collectData.userId = userId)
	kvMap && (collectData.kvMap = encodeURI(JSON.stringify(kvMap)))

	// 数据上报
	var fileUrl = formatGetParams(JGAnalysisBaseUrl + '/fe/collectData.gif', collectData)
	uploadData(2, fileUrl, collectData)
}

// 上报数据
function uploadData(type, fileUrl, data) {
  console.log(fileUrl)
  console.log(uni)
	// thpe 1-注册 2-埋点
	var img = document.createElement("img")
		try {
			img.src = fileUrl
			toast(type === 1 ? '注册成功' : '埋点成功', data)
		} catch (e) {
			console.info(type === 1 ? '注册失败' : '埋点失败', e)
		}

}

function getBaseParams() {
	let systemInfo;
	let channelId;
  console.log(uni)
  systemInfo = {
    osName: getExploreName(),
    osVersion: getExplore(),
  }
  channelId = 'HCQ-H5'
	let baseParams = {
		appId: app_id,

		deviceCode: systemInfo.deviceId,
		deviceMachineCode: systemInfo.deviceId,
		operatingSystem: systemInfo.osName,
		version: systemInfo.osVersion,
		clientBrand: systemInfo.brand || systemInfo.deviceBrand,
		clientModelNum: systemInfo.deviceModel,

		sessionId: session_id,
		channelId: channelId,
		createTime: Date.now(),
		netModel: network_type,
		name: '',
		appVersion: ''
	}
	return baseParams
}

/* 
 *
 *
 * 思路：
 * 1、定义一个空字符串，用来存放待会儿拼接后的url地址
 * 2、遍历传进来的对象形式的参数
 * 3、如果当前参数不为null和空字符串，则进入if判断进行拼接
 * 4、第一个参数前拼接上?
 * 5、后面的参数前拼接上&
 * 6、将最后拼接好的字符串return出去，切记不要不小心写在for循环里。
 * 
 */
function formatGetParams(url, data) {
	let params = ``;
	for (let key in data) {
		if (data[key] != null && data[key] != '') {
			if (params.length == 0) {
				params += `?${key}=${data[key]}`;
			} else {
				params += `&${key}=${data[key]}`;
			}
		}
	}
	url += params;
	return url;
}

// 获取浏览器类型
function getExplore() {
	var Sys = {};
	var ua = navigator.userAgent.toLowerCase();
	var s;
	(s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
		(s = ua.match(/msie ([\d\.]+)/)) ? Sys.ie = s[1] :
			(s = ua.match(/edge\/([\d\.]+)/)) ? Sys.edge = s[1] :
				(s = ua.match(/firefox\/([\d\.]+)/)) ? Sys.firefox = s[1] :
					(s = ua.match(/(?:opera|opr).([\d\.]+)/)) ? Sys.opera = s[1] :
						(s = ua.match(/chrome\/([\d\.]+)/)) ? Sys.chrome = s[1] :
							(s = ua.match(/version\/([\d\.]+).*safari/)) ? Sys.safari = s[1] : 0;
	// 根据关系进行判断
	if (Sys.ie) return ('IE: ' + Sys.ie);
	if (Sys.edge) return ('EDGE: ' + Sys.edge);
	if (Sys.firefox) return ('Firefox: ' + Sys.firefox);
	if (Sys.chrome) return ('Chrome: ' + Sys.chrome);
	if (Sys.opera) return ('Opera: ' + Sys.opera);
	if (Sys.safari) return ('Safari: ' + Sys.safari);
	return 'Unkonwn';
}

// 获取浏览器名称
function getExploreName() {
	var userAgent = navigator.userAgent;
	if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
		return 'Opera';
	} else if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1) {
		return 'IE';
	} else if (userAgent.indexOf("Edge") > -1) {
		return 'Edge';
	} else if (userAgent.indexOf("Firefox") > -1) {
		return 'Firefox';
	} else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1) {
		return 'Safari';
	} else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1) {
		return 'Chrome';
	} else if (!!window.ActiveXObject || "ActiveXObject" in window) {
		return 'IE>=11';
	} else {
		return 'Unkonwn';
	}
}

// 生成随机字符串
function randomString(randomLen, min, max) {
	var str = "",
		range = min,
		arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
			'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k',
			'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v',
			'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F',
			'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
			'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
		];
	// 随机产生
	if (randomLen) {
		range = Math.round(Math.random() * (max - min)) + min;
	}
	for (var i = 0; i < range; i++) {
		pos = Math.round(Math.random() * (arr.length - 1));
		str += arr[pos];
	}
	return str;
}

export default {
	init,
	onEvent,
}
