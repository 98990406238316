import Vue from 'vue'
import Router from "vue-router"
import { Toast } from 'vant';
import { loginByMobileAndSignature } from "@/util/api";
import store from '@/store'


// import store from '../store/index'

Vue.use(Router)

// let linkType =''
const routes = [
  {
    path:  '/home',
    component: () => import('@/views/home/home.vue')
  },
  {
    path:  '/login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path:  '/withdraw',
    component: () => import('@/views/withdraw/withdraw.vue')
  },
  {
    path:  '/success',
    component: () => import('@/views/success/success.vue')
  },
  {
    path:  '/realName',
    component: () => import('@/views/realName/realName.vue')
  },
  {
    path:  '/sign',
    component: () => import('@/views/sign/sign.vue')
  },
  {
    path:  '/esign1',
    component: () => import('@/views/sign/esign.vue')
  },
  {
    path:  `/`,
    component: () => import('@/views/redirectPage/redirectPage.vue'),
  },
  {
    path:  `/:linkCode/:linkType`,
    component: () => import('@/views/redirectPage/redirectPage.vue'),
  },
  {
    path:  '/esign',
    component: () => import('@/views/esign/esign.vue')
  },
  {
    path: '/enterpriseCertification',
    component: () => import('@/views/enterpriseCertification/enterpriseCertification.vue')
  },
  {
    path: '/phaseTwo',
    component: () => import('@/views/enterpriseCertification/phaseTwo.vue')
  },
  {
    path: '/authenticationPage',
    component: () => import('@/views/enterpriseCertification/authenticationPage.vue')
  },
  {
    path: '/licenseAgreement',
    component: () => import('@/views/enterpriseCertification/licenseAgreement.vue')
  },
  {
    path: '/usePage',
    component: () => import('@/views/enterpriseCertification/usePage.vue')
  },
  {
    path:  '/details',
    component: () => import('@/views/details/details.vue') // 查看合同详情
  },
  {
    path:  '/pdf',
    component: () => import('@/views/details/pdf.vue') // 查看合同详情
  },
  {
    path:  '/videoCertification',
    component: () => import('@/views/videoCertification/index.vue') // 人脸实名认证
  },
  { //  人脸+ 手机号  中间页
    path: '/faceCenterPage',
    component: () => import('@/views/enterpriseCertification/faceCenterPage.vue')
  },
]

const router = new Router({
  routes,
  mode: 'hash',
  scrollBehavior() {
    // return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  const {
    sourceType,
    mobilePhone,
    signatureId,
    decryptId
  } = to.query
  if(sourceType === 'miniWx') {
    sessionStorage.setItem('sourceType', 'miniWx')
    if(mobilePhone && signatureId && decryptId) {
      // miniWxLogin(mobilePhone, decryptId)
      loginByMobileAndSignature({
        mobilePhone,
        signatureId: decryptId
      }).then(res=>{
        if(res.curSignatureId) {
          store.commit('setUserInfo',res)
          sessionStorage.setItem('sourceType', 'miniWx')
          router.replace({ path: '/sign',query:{signatureId:res.curSignatureId}})
          // next(`/sign?signatureId=${res.curSignatureId}`)
        }
      })
    } else {
      Toast.fail(`${'合同id不存在'}`);
    }
  } else {
    next()
  }
})
export default router