import axios from "axios";

/**
 * 获取小程序Scheme
 * @param {{ appId: string }} data
 * @returns 
 */
export const getMiniProgramScheme = (data) => {
  return axios.post(`/auth/generatescheme`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 发送认证验证码
 * @param {{ captcha: any, mobilePhone: string }} data
 * @returns 
 */
export const sendAuthCodeByMobile = (data) => {
  return axios.post('/auth/sendAuthCodeByMobile', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 手机验证码登录
 * @param {{ authCode: string, mobilePhone: string }} data
 * @returns 
 */
export const loginByMobileAndAuthCode = (data) => {
  return axios.post('/auth/loginByMobileAndAuthCode', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 实名认证
 * @param {{ authCode: string, mobile: string, idcard: string, name: string }} data
 * @returns 
 */
export const realname = (data) => {
  return axios.post('/auth/realname',  data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 通过签约人以及租户登录
 * @param {{ signerId: string, tenantId: string }} data
 * @returns 
 */
export const loginBySignerIdAndTenant = (data) => {
  return axios.post('/auth/loginBySignerIdAndTenant', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 签约详情（通过签约ID:signatureId 查询）
 * @param {{ signatureId: FormData }} data
 * @returns 
 */
export const signatureDetails = (data) => {
  return axios.post('/signature/signatureDetails', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 签约位置（通过签约ID:signatureId 查询）
 * @param {{ signatureId: FormData }} data
 * @returns 
 */
export const signPositionBySignature = (data) => {
  return axios.post('/signature/signPositionBySignature', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * ossPreviewUrl oss 上传文件
 * @param {{ fileDisplayName: string, fileExt: string, fileSize: string, fileUrl: string }} data
 * @returns 
 */
export const savePersonSeal = (data) => {
  return axios.post('/signature/savePersonSeal', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * doSignature
 * @returns 
 */
export const doSignature = (data) => {
  return axios.post('/api/doSignature', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 通过linkcode 查询信息
 * @param {string} linkCode
 * @returns 
 */
export const getProcessInfoByLinkCode = (data) => {
  return axios.post('/signature/getProcessInfoByLinkCode', data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 个人签名列表（通过processId 查询）
 * @param {string} processId
 * @returns 
 */
export const personSealList = (data) => {
  return axios.post(`/signature/personSealList`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 签约（多个签约过程
 * @param {{ actionType: boolean, detail: { positionId: string, sealId: string, sealImgUrl: string, signDate: string, signDateUrl: string }, processId: string, rejectReason: string, remarks: string }} data
 * @returns 
 */
export const signature = (data) => {
  return axios.post(`/signature/batchSignForm`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 删除签名
 * @param {{ processId: string, sealId: string }} data
 * @returns 
 */
export const deleteSeal = (data) => {
  return axios.post(`/signature/deleteSeal`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 删除签名
 * @param {{ signatureId: string }} data
 * @returns 
 */
export const withdraw = (data) => {
  return axios.post(`/signature/withdraw?signatureId=${data.signatureId}`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * queryDocFilesByDocumentNosAndTenantId
 * @param {{ documentNos: string, tenantId: string }} data
 * @returns 
 */
export const queryDocFilesByDocumentNosAndTenantId = (data) => {
  return axios.post(`/api/ossPreviewUrlByDocumentNosAndTenantId`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 获取当前登录用户
 * @param {} 
 * @returns 
 */
export const getCurUserInfo = () => {
  return axios.post(`/auth/getCurUserInfo`, {}, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 获取当前登录用户
 * @param {} 
 * @returns 
 */
export const previewUrl = (data) => {
  return axios.post(`/api/previewUrl?docFileId=${data.docFileId}`, {}, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * ossPreviewUrl
 * @param {} 
 * @returns 
 */
export const ossPreviewUrl = (data) => {
  console.log(data,'ossUrl');
  return axios.post(`/api/ossPreviewUrl?path=${data.path}`, {}, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 下载 （会记录日志）
 * @param {{ signatureId: string }} data
 * @returns 
 */
export const download = (data) => {
  return axios.post(`/signature/download?signatureId=${data.signatureId}`, data, {
    responseType: "arraybuffer",
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 下载 （会记录日志）
 * @param {{ tenantSealId: string }} data
 * @returns 
 */
export const sealDetail = (data) => {
  return axios.post(`/signature/sealDetail?tenantSealId=${data.tenantSealId}`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 文本转图片
 * @param {{ text: string }} data
 * @returns 
 */
export const textToImg = (data) => {
  return axios.post(`/signature/textToImg`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * ORC证件识别
 * @param data
 * @returns
 */
export const getCredentialsInfo = (data) => {
  return axios.post(`/realname/ocr/getCredentialsInfo`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 提交基本信息
 * @param data
 * @returns
 */
export const saveCompanyBaseInfo = (data) => {
  return axios.post(`/realname/saveCompanyBaseInfo`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX 
  })
}
/**
 * 提交认证资料
 * @param data
 * @returns
 */
export const saveCompanyAuthenticationInfo = (data) => {
  return axios.post(`/realname/saveCompanyAuthenticationInfo`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX 
  })
}

/**
 * 获取刷脸图片
 * @param {}
 * @returns
 */
 export const faceResultSimple = (verifyToken) => {
  return axios.post(`/faceRecognition/faceResultSimple?verifyToken=${verifyToken}`,{}, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 查看合同详情（原文或者详情）
 * @param {}
 * @returns
 */
 export const viewSignature = (data) => {
  return axios.post(`/signature/viewSignature`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 判断企业是否实名认证
 * @param {}
 * @returns
 */
export const getCompanyRealnameInfo = (companyId) => {
  return axios.post(`/realname/getCompanyRealnameInfo?companyId=${companyId}`,{}, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 获取授权申请详情
 * @param data
 * @returns
 */
export const getApplyDetail = (data) => {
  return axios.post(`/signAuthority/getApplyDetail`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 法定代表人/管理员授权
 * @param data
 * @returns
 */
export const grantAuthorization = (data) => {
  return axios.post(`/signAuthority/grantAuthorization`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 获取登录信息
 * @param data
 * @returns
 */
export const getLoginInfoByLinkCode = (data) => {
  return axios.post(`/signature/getLoginInfoByLinkCode`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 提交审核
 * @param data
 * @returns
 */
export const submitApply = (data) => {
  return axios.post(`/realname/submitApply`,data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 下载授权书
 * @param 
 * @returns
 */
export const authorizationDownload = (data) => {
  return axios.post(`/realname/downloadAuthorization?companyId=${data.companyId}`,data,{
    responseType: "arraybuffer",
    baseURL: process.env.VUE_APP_API_PREFIX,
  })
}
/**
 * 下载授权书文件 - 新
 * @param 
 * @returns
 */
 export const downloadAuthorizationFile = (data) => {
  return axios.post(`/jyq-con-esign/thirdplatform/companyrealname/downloadAuthorizationFile?companyId=${data.companyId}`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX,
  })
}
/**
 * 获取租户账户信息展示使用
 * @param
 * @rerurns
 */
export const queryTenantTaxInfo = (data) => {
  return axios.post(`/realname/queryTenantTaxInfo?companyId=${data.companyId}`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 登录完成后通过linkCode来确认跳转页面
 * @param
 * @returns
 */
export const getCertificationInfo = (linkCode) => {
  return axios.post(`/realname/getCertificationInfo?linkCode=${linkCode}`,{},{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 验证签约短信验证码
 * @param 
 * @returns
 */
export const checkSignSms = (data) => {
  return axios.post(`/signature/checkSignSms`, data, {
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 人脸验证获取识别地址
 * @param
 * @returns
 */
export const getRecognitionUrl = (data) => {
  return axios.post(`/realname/getLegalRepresentativeRecognitionUrl`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 获取实名认证印章列表
 * @param
 * @returns
 */
export const selectSealList = (data) => {
  return axios.post(`/signature/companySealList?companyId=${data.companyId}&signatureId=${data.signatureId}`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 更新实名认证印章
 * @param
 * @returns
 */
export const updateSeal = (data) => {
  return axios.post(`/realname/updateSeal`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 查看签约人待签署合同
 * @param
 * @returns
 */
 export const getSignatureToSignList  = (data) => {
  return axios.post(`/realname/getSignatureToSignList`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 判断签署人是否有签署权限
 * @param
 * @returns
 */
export const hasAuthority = (data) => {
  return axios.post(`/signAuthority/hasAuthority`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 申请权限
 * @param
 * @returns
 */
export const applyAuthority = (data) => {
  return axios.post(`/signAuthority/applyAuthority`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 获取签署人申请签署权限结果
 * @param
 * @returns
 */
export const getApplyAuthorityResult = (linkCode) => {
  return axios.post(`signAuthority/getApplyAuthorityResult?linkCode=${linkCode}`,{},{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
//  实名认证+ 人脸识别
/**
 * 是否有实名认证中的数据
 * @param
 * @returns
 */
export const hasRealnameExam = () => {
  return axios.post('/auth/hasRealnameExam',{},{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 获取参数   num  数值
 * @param
 * @returns
 */
export const facelivenessSession = () => {
  return axios.post('/faceRecognition/facelivenessSession',{},{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * facelivenessVerify 提交人脸数据
 * @param
 * @returns
 */
export const facelivenessVerify = (data = {}) => {
  return axios.post('/faceRecognition/facelivenessVerify',data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 通过linkcode获取实名认证信息
 * @param
 * @returns
 */
 export const getRealNameInfoByLinkCode = (data = {}) => {
  return axios.post(`/auth/getRealNameInfoByLinkCode?linkCode=${data.linkCode}`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 合同分页列表
 * @param
 * @returns
 */
 export const querySignaturePage = (data = {}) => {
  return axios.post('/realname/querySignaturePage',data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 获取枚举List
 * @param
 * @returns
 */
 export const getEnumListByCls = (data = {}) => {
  return axios.post(`/api/getEnumListByCls?enumClassName=${data.enumClassName}`,data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}

/**
 * 合同分页列表
 * @param
 * @returns
 */
 export const saveErrorLogger = (data = {}) => {
  return axios.post('/auth/saveErrorLogger',data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}
/**
 * 通过手机号和加密的签约（合同）ID登录
 * @param
 * @returns
 */
 export const loginByMobileAndSignature = (data = {}) => {
  return axios.post('/auth/loginByMobileAndSignature',data,{
    baseURL: process.env.VUE_APP_API_PREFIX
  })
}