import Vuex from 'vuex'
import Vue from 'vue'
import VuexPersistence from 'vuex-persist'
import createLogger from 'vuex/dist/logger'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    appType: '',
    linkType:null,
    linkCode:null,
    tenantId:null,
    loginName:null,
    userInfo: {},
    signatureId: null,
    linkCodeInfo: {},
    sign: false,
    certificationInfo: null,
    signatureDetails: {},
    resultInfo: null,
    signatureParam: {},
    stateallSignData: null,
    stateposiInfo: null,
    preP: null,
    scrollTop: 0,
    signList: [],
    signPartyType: null,
    resultStatus: null,
    verifyToken: '', // 刷脸图片token
    accesstoken: '' // 登录token
  },
  mutations: {
    removeAllState(state) {
      state.userInfo =  {};
      state.signatureId = null ;
      state.linkCodeInfo = {};
      state.sign = false;
      state.certificationInfo = null;
      state.signatureDetails = {} ;
      state.resultInfo = null ;
      state.signatureParam = {} ;
      state.stateallSignData = null;
      state.stateposiInfo = null ;
      state.preP = null ;
      state.scrollTop = 0;
      state.signList = [];
      state.signPartyType = null ;
      state.resultStatus = null ;
      state.verifyToken = '' ;// 刷脸图片token
    } ,
    setAppType (state, appType) {
      state.appType = appType
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    setTenantId (state, tenantId) {
      state.tenantId = tenantId
    },
    setLinkType (state, linkType) {
      state.linkType = linkType
    },
    setLinkCode (state, linkCode) {
      state.linkCode = linkCode
    },
    setLoginName (state, loginName) {
      state.loginName = loginName
    },
    setSignatureId (state, signatureId) {
      state.signatureId = signatureId
    },
    setLinkCodeInfo (state, linkCodeInfo) {
      console.log(linkCodeInfo,1111);
      state.linkCodeInfo = JSON.parse(JSON.stringify(linkCodeInfo))
    },
    setSignatureDetails (state, signatureDetails) {
      state.signatureDetails = signatureDetails
    },
    signOver (state, sign) {
      state.sign = sign
    },
    setCertificationInfo(state,certificationInfo) {
      console.log(certificationInfo,'111');
      state.certificationInfo = certificationInfo
    },
    setResultInfo(state,resultInfo) {
      state.resultInfo = resultInfo
    },
    setSignatureParam(state, signatureParam) {
      state.signatureParam = signatureParam
    },
    setAllSignData(state, stateallSignData) {
      state.stateallSignData = stateallSignData
    },
    setPosiInfo(state, stateposiInfo) {
      state.stateposiInfo = stateposiInfo
    },
    setPreP(state, preP) {
      state.preP = preP
    },
    setScrollTop(state,scrollTop) {
      state.scrollTop = scrollTop
    },
    setSignList(state,signList) {
      state.signList = signList
    },
    setSignPartyType(state,signPartyType) {
      state.signPartyType = signPartyType
    },
    setResultStatus(state,resultStatus) {
      state.resultStatus = resultStatus
    },
    setVerifyToken (state, verifyToken) {
      state.verifyToken = verifyToken
    },
    setAccesstoken (state, accesstoken) {
      state.accesstoken = accesstoken
    },
  },
  plugins: [createLogger(), vuexLocal.plugin]
})

export default store
