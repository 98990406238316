import Vue from 'vue'
import Vant from 'vant';
import App from './App.vue'
import 'amfe-flexible'
import 'vant/lib/index.css';
import './index.less'
import router from './router';
import axios from 'axios'
import {
  Toast
} from 'vant';
import store from './store'
import vueEsign from 'vue-esign'
import {
  Dialog
} from 'vant';
import { saveErrorLogger } from "@/util/api";

import Analysis from '@/util/jgAnalysis.js'
Vue.prototype.$Analysis = Analysis

Vue.use(Dialog);
Vue.use(vueEsign)
Vue.use(Vant);
Vue.config.productionTip = false

setTimeout(()=>{
  require('amfe-flexible')
},700)

const errorHandler = (error, vm)=>{
  // console.log(errorStack);
  console.error('抛出全局异常');
  // console.log(store.state)
  console.error(vm);
  console.error(error);
  // console.log(typeof error, 'error' )
  // console.log(error, 'error' )
  // console.log(error.message, 'error' )
  // console.log(error.errorStack, 'error' )
  // console.log(error.stack, 'error' )
  // console.log(typeof error.stack, 'error' )
  // console.log(vm, 'vm')
  // debugger;

  let err = error.toString()
  if (store.state.appType == 'browser' && store.state.accesstoken == '' || !store.state.accesstoken) {
    saveErrorLogger(err).then(() => {
    })
    //  router.push({path: '/login'})
  }
}
Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error)=> errorHandler(error,this);

// const miniWxLogin = (mobilePhone='', signatureId='') => {
//   loginByMobileAndSignature({
//     mobilePhone,
//     signatureId
//   }).then(res=>{
//     if(res.curSignatureId) {
//       store.commit('setUserInfo',res)
//       sessionStorage.setItem('sourceType', 'miniWx')
//       router.push({ path: '/sign',query:{signatureId:res.curSignatureId}})

//     }
//   })
// }
// Vue.config.miniWxLogin = miniWxLogin;

axios.interceptors.request.use(config => {
  console.log(config, 'config')
  Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true, // 禁用背景点击
    loadingType: 'spinner',
    message: '正在加载'
  })
  const {
    accesstoken
  } = sessionStorage
  if (accesstoken) {
    config.headers['accesstoken'] = accesstoken
  } else if (store.state.accesstoken) {
    config.headers['accesstoken'] = store.state.accesstoken
  }
  return config
})

axios.interceptors.response.use(options => {
  const {
    status,
    data = {},
    statusText = '网络错误',
    headers: {
      accesstoken
    } = {},
    request: {
      responseURL = ''
    } = {},
    headers = {}
  } = options || {}
  Toast.clear()
  console.log(options, 'status')
  if (status === 200) {
    console.log(options);
    const {
      code,
      msg,
      data: res,
      extraData
    } = data
    if (responseURL.includes('/signature/download') || responseURL.includes('/realname/downloadAuthorization')) {
      const type = headers['content-type']
      const fileName = headers['content-disposition'].split('filename=')[1] || '未知文件.pdf'
      let msg = window.decodeURI(headers['_x_fw_rest_msg'])
      let code = headers['_x_fw_rest_code']?headers['_x_fw_rest_code']: 0
      return Promise.resolve({
        data,
        type,
        fileName,
        code,
        msg
      })
    }
    if (accesstoken) {
      sessionStorage.accesstoken = accesstoken
      store.state.accesstoken = accesstoken
    } else if (store.state.accesstoken) {
      sessionStorage.accesstoken = store.state.accesstoken
    }
    if (code === 0 || code === 50001) {
      return res || extraData || code
    } else {
      if (code === 4) {
        // 判断是否通过小程序进入 然后通过合同加密合同id和手机好自动登入
        // const {
        //   sourceType,
        //   mobilePhone,
        //   signatureId,
        //   decryptId
        // } = router.history.current.query
        // if(sourceType === 'miniWx') {
        //   if(mobilePhone && signatureId && decryptId) {
        //     miniWxLogin(mobilePhone, decryptId)
        //   } else {
        //     Toast.fail(`${'合同id不存在'}`);
        //   }
        // } else 
        if (store.state.appType == 'browser') {
          //登录失效后能回到原来的页面
          try {
            const {
              linkCode,
              linkType,
              loginName,
              tenantId
            } = store.state
            console.log('linkType', linkType);
            console.log('linkCode', linkCode);
            console.log('loginName', loginName);
            console.log('tenantId', tenantId);
            router.push({
              path: '/login',
              query: {
                linkCode,
                linkType,
                loginName,
                tenantId
              }
            })
            /**
            const {
              signerContact,
              tenantId,
              signatureId,
              partyId,
              processId,
              signPartyType,
              signerId
            } = store.state.linkCodeInfo
            router.push({
              path: '/login',
              query: {
                signerContact,
                tenantId,
                signatureId,
                partyId,
                processId,
                signPartyType,
                signerId
              }
            })
            ***/
          } catch (error) {
            router.push({
              path: '/login'
            })
            Toast.fail(`${'请从短信进入！'}`);
          }
        }
      } else {
        Toast.fail(`${msg}`);
      }
      return Promise.reject(data)
    }
  }
  Toast.fail(`${statusText}`);
  return Promise.reject(statusText)
},error => { // for debug
  Toast.clear()
  Toast.fail('网络错误');
  console.log(error, 'error')
  saveErrorLogger(error).then(() => {
  })
})

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')